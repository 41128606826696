import React from "react";
import { Link } from "gatsby"
import Header from "../components/Header"
import "../ui-lib/styles/404.scss"

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {

    return (
        browser && (
        <div className="notfoundpage--wrap">
            <Header headerClass="headercolor" />
            <div className="notfoundpage">
                <img src="/pagenotfound.svg" alt="page not found"/>
                <h2>404</h2>
                <h3>It looks like you're lost...</h3>
                <Link to="/" className="notfoundpage--anchor">Go Back Home</Link>
            </div>
        </div>
        )
    )
}
export default NotFoundPage